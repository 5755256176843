.My-pets-vet-card-container {
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eceef0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  margin: 12px 0 0;
  min-width: 384px;
  position: relative;
  width: auto;
}

.My-pets-vet-image-container {
  align-items: center;
  border-radius: 50%;
  height: 65px;
  justify-content: center;
  margin: 20px;
  width: 65px;
}

.My-pets-vet-no-image {
  height: 65px;
  margin-top: 3px;
  width: 65px;
}

.My-pets-vet-info-address {
  padding-right: 16px !important;
}

.My-pets-vet-image {
  border-radius: 50%;
  height: 65px;
  width: 65px;
}

.Remove-vet {
  background-color: transparent;
  border: none;
  height: fit-content;
  margin: 10px 16px 0 0;
  padding: 0;
  position: absolute;
  right: 0;
  width: fit-content;
}

.My-pets-vet-info-container {
  align-items: center;
  display: flex;
  gap: 45px;
  justify-content: space-between;
  margin: 16px 34px 15px 0;
}

.My-pets-vet-info-name {
  box-sizing: border-box;
  font-family: "Gotham-Rounded";
  font-size: 18px;
  font-weight: 350;
  line-height: 24px;
  padding-right: 10px;
  width: 175px
}

.My-pets-vet-info-address {
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  font-family: "Gotham-Rounded";
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
}

.My-pets-vet-info-telephone {
  box-sizing: border-box;
  flex-grow: 1;
  font-family: "Gotham-Rounded";
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
  width: 155px;
}

.Remove-vet-container {
  align-items: center;
  justify-content: center;
}

.Remove-vet-icon {
  height: 18px;
  width: 18px;
}

.My-pets-vet-info-container > p,
.My-pets-vet-info-address > p {
  margin: 0;
}

.Remove-my-pets-vet-card-text {
  color: #22435e;
  font-family: "Inter-Regular";
  font-size: 12px;
  margin: 16px 32px 13px;
  text-align: center;
}

.Remove-my-pets-vet-card-text > span {
  font-family: "Inter-Medium";
}

.Remove-my-pets-vet {
  align-items: center;
  border-radius: 5px;
  color: #fff;
  display: flex;
  font-family: "Inter-SemiBold";
  font-size: 12px;
  height: 30px;
  justify-content: flex-end;
  margin-bottom: 16px;
  padding: 0 !important;
  position: absolute;
  right: 24px;
  top: 40px;
  width: 98px;
}

.Remove-my-pets-vet-button:first-child {
  background-color: #6d7579;
  margin-right: 8px;
}

.Remove-my-pets-vet-button:hover {
  color: #fff;
  opacity: 0.8;
}

@media (max-width: 1295px) {
  .My-pets-vet-info-container {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    width: 100%;
    margin: 18px 0 18px 0;
    min-width: 436px;
  }

  .My-pets-vet-info-name {
    grid-column: 1;
    grid-row: 1;
    width: 200px;
  }

  .My-pets-vet-info-telephone {
    grid-column: 1;
    grid-row: 2;
    width: 150px;
  }

  .My-pets-vet-info-address {
    grid-column: 2;
    grid-row: 1 / span 2;
    width: 200px;
  }

  .Remove-my-pets-vet {
    align-items: end;
    align-self: center;
    grid-column: 3;
    grid-row: 1 / span 2;
    right: 24px;
    top: inherit;
  }
}

@media (max-width: 900px) {
  .My-pets-vet-info-name {
    width: 150px;
  }

  .My-pets-vet-info-address {
    width: 150px;
    font-size: 15px;
  }
}

@media (max-width: 650px) {
  .My-pets-vet-info-container {
    min-width: 340px;
  }
}

@media (max-width: 600px) {
  .My-pets-vet-info-container {
    min-width: 300px;
  }

  .Remove-my-pets-vet {
    right: 14px;
  }

  .My-pets-vet-info-name {
    font-size: 16px;
    width: 120px;
  }

  .My-pets-vet-info-telephone {
    font-size: 15px;
  }

  .My-pets-vet-info-address {
    right: 30px;
    width: 112px;
    right: 24px;
    font-size: 15px;
    position: absolute;
  }
}
