.My-pets-buttons-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  max-width: 1170px;
  width: 100%;
}

.My-pets-add-a-pet-button,
.My-pets-back-button {
  border-radius: 8px;
  border-style: none;
  color: #fff;
  transition: all 0.2s ease;
}

.My-pets-add-a-pet-button {
  background-color: #2fb3b4;
  height: 48px;
  width: 139px;
}

.My-pets-back-button {
  background: linear-gradient(180deg, rgba(44,96,133,1) 0%, rgba(24,47,67,1) 100%);
  height: 40px;
  margin-left: 2px;
  width: 122px;
}

.My-pets-add-a-pet-button:hover,
.My-pets-back-button:hover {
  opacity: 0.8;
}

/* Some large devices */
@media (max-width: 1410px) {
  .My-pets-add-a-pet-button {
    margin-right: 28px;
  }
}

/* Tablet - Big */
@media (max-width: 1200px) {
  .My-pets-add-a-pet-button {
    margin-right: 0;
  }

  .My-pets-content {
    padding-left: 50px;
  }
}
