.My-pets-manual-add-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 24px;
  width: 520px;
}

.My-pets-manual-add-title-container {
  display: flex;
  flex-direction: column;
}

.My-pets-manual-add-title-box {
  display: flex;
  justify-content: space-between;
}

.My-pets-manual-add-title {
  color: var(--charcoal-500);
  font-family: "Gotham-Rounded";
  font-size: 24px;
  font-weight: 350;
  line-height: 0.91;
  text-align: left;
}

.My-pets-manual-add-vet-modal-subtitle {
  color: var(--charcoal-300);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  margin: 0 !important;
}

.My-pets-manual-add-input-titles {
  color: var(--pc-color-onyx-500);
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-weight: 350;
  line-height: 16px;
  margin-bottom: 8px;
}

.My-pets-manual-add-input-city {
  width: 254px;
}

.My-pets-manual-add-select {
  appearance: none;
  background: #fff url("../../../assets/myVets/chevron.svg") no-repeat right .75rem center/18px 18px;
  border-radius: 8px;
  border: 2px solid var(--pc-color-onyx-100);
  height: 41px;
  padding: 8px;
  width: 193px;
}

.My-pets-manual-add-select {
  color: var(--pc-color-onyx-300);
}

div .My-pets-manual-add-select:focus {
  border: none !important;
  box-shadow: none !important;
}

.My-pets-manual-add-select:focus {
  border-radius: 8px;
  border: 2px solid var(--pc-color-blue-300);
  outline: 2px solid var(--pc-color-blue-300);
}

.My-pets-manual-add-input-container {
  display: flex;
  flex-direction: column;
}

.My-pets-manual-add-input-container > input {
  padding-left: 8px !important;
}

.My-pets-manual-add-select > option {
  color: var(--charcoal-500);
  font-family: "Gotham-Regular";
  font-weight: 400;
  line-height: 19.2px;
  margin: 4px;
}

.My-pets-manual-add-input-name:focus,
.My-pets-manual-add-input-city:focus {
  border-radius: 8px;
  border: 2px solid var(--pc-color-blue-300);
  outline: 2px solid var(--pc-color-blue-300);
}

.My-pets-manual-add-input-container > input {
  border-radius: 8px;
  border: 2px solid var(--pc-color-onyx-100);
  display: flex;
  flex-direction: column;
}

div .My-pets-manual-add-input-name:focus,
.My-pets-manual-add-input-city:focus {
  border: none !important;
  box-shadow: none !important;
}

.My-pets-manual-add-inputs-row {
  display: flex;
  gap: 25px;
  margin-top: 14px;
}

.My-pets-manual-add-buttons {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.My-pets-manual-add-button {
  margin: 0;
}

.My-pets-manual-add-save-btn {
  align-items: center;
  background-color: #22435e;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  width: 116px;
}

.My-pets-manual-add-save-btn > .Loading-button-text {
  font-family: 'Inter-Regular';
}

.My-pets-manual-add-save-btn:hover {
  background-color: #203e57;
}

.My-pets-manual-add-close-btn {
  display: flex;
  justify-content: flex-end;
}

.My-pets-manual-add-close-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.My-pets-manual-add-close-btn:focus {
  outline: none;
}

.My-pets-manual-add-cancel-btn {
  background-color: #b0bbbf;
  border-radius: 5px;
  border: 1px solid #b0bbbf;
  color: #fff;
  height: 48px;
  margin-right: 16px;
  width: 116px;
}

.My-pets-manual-add-cancel-btn:hover {
  background-color: #b0b0b0;
}

.My-pets-manual-add-error-message {
  color: #cc0000;
  font-family: 'Inter-Regular';
  font-size: 13px;
  margin-top: 8px;
}

.My-pets-manual-add-check {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.My-pets-manual-add-vet-check-name,
.My-pets-manual-add-vet-check-state {
  width: auto;
}

.My-pets-manual-add-vet-check-name {
  color: #000;
  font-family: "Gotham-Rounded";
  font-size: 16px;
  font-weight: 350;
  line-height: 19.2px;
}

.My-pets-manual-add-vet-check-state {
  color: var(--pc-color-onyx-300);
  display: flex;
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-weight: 400;
  gap: 4px;
  line-height: 19.2px;
}
