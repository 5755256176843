.No-vet-modal-container {
  padding: 32px 40px;
}

.No-vet-modal-container > h3 {
  color: var(--title);
  font-family: 'Inter-Semibold';
  font-size: 22px;
  margin: 0 0 24px;
  max-width: 276px;
  text-align: center;
}

.No-vet-modal-container > p {
  color: #3d484d;
  margin-bottom: 32px;
  max-width: 318px;
  text-align: center;
}

.No-vet-modal-action-button {
  background-color: var(--pc-color-blue-300);
  border-radius: 8px;
  border: none;
  color: #fff;
  font-family: 'Inter-Semibold';
  padding: 14px 32px;
}
