.Found-vet-item-data {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.Found-vet-item-name {
  color: #1e2226;
  font-size: 15px;
}

.Found-vet-item-address {
  color: #6d7579;
  font-family: 'Inter-Medium';
  font-size: 12px;
}

.Found-vet-item-data-margin {
  margin-right: 14px;
}

.Found-vet-item-distance {
  color: #22435e;
  font-family: 'Inter-Medium';
  font-size: 12px;
}

.Found-vet-item-added {
  display: flex;
  flex: 1;
  font-family: 'Inter-Semibold';
  font-size: 13.5px;
  justify-content: flex-end;
  margin: 0 20px;
}

.Found-vet-item-added > img {
  margin-right: 4px;
}

.Found-vet-item-extra-text {
  align-self: center;
  display: flex;
  flex: 1;
  font-size: 13.5px;
  justify-content: flex-end;
  text-decoration: underline;
}
