.My-pets-add-vet-modal {
  padding: 26px;
  width: 520px;
}

.My-pets-add-vet-modal-margin {
  margin-bottom: 24px !important;
}

.My-pets-add-vet-modal-close {
  background: none;
  border: none !important;
  outline: none !important;
  padding: 0;
  position: absolute;
  right: 40px;
  top: 16px;
}

.My-pets-add-vet-modal > h2 {
  color: #000;
  font-family: 'Gotham-Rounded';
  font-size: 24px;
  font-weight: 350;
  margin: 0;
}

.My-pets-add-vet-modal-search {
  color: #000;
  display: inline-block;
  font-size: 14px;
  font-weight: 350;
  margin: 12px 4px 8px 2px;
}

.My-pets-separator {
  color: var(--pc-color-onyx-200);
  font-size: 12px;
  font-weight: 350;
  line-height: 16.8px;
}

.My-pets-add-vet-modal-subtitle {
  color: var(--charcoal-300);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  margin: 0 !important;
  padding-top: 4px;
}

.My-pets-add-vet-modal-zipcode {
  color: #000;
  display: inline-block;
  font-size: 14px;
  font-weight: 350;
  margin: 24px 4px 16px 0;
}

.My-pets-add-vet-modal-change {
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Rounded';
  font-size: 14px;
  font-weight: 350;
  margin: 14px 4px 16px 6px;
  text-decoration: underline;
}

.My-pets-add-vet-modal-not-listed {
  color: var(--pc-color-blue-300);
  font-family: 'Gotham-Rounded';
  font-size: 12px;
  margin-bottom: 18px;
  text-decoration: underline;
}

.My-pets-add-vet-modal-not-listed-hr {
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
}

.My-pets-add-vet-modal-selected-vet {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 48px 0 24px;
}

.My-pets-add-vet-modal-vet-name {
  color: #000;
  font-family: "Gotham-Rounded";
  font-size: 16px;
  font-weight: 350;
  line-height: 19.2px;
}

.My-pets-add-vet-modal-vet-name,
.My-pets-add-vet-modal-vet-address-location {
  width: auto;
}

.My-pets-add-vet-modal-vet-address-location {
  color: var(--charcoal-300);
  font-family: "Gotham-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  margin: 0 !important;
}

.My-pets-add-vet-modal-vet-address {
  display: flex;
  flex-direction: column;
}

.My-pets-add-vet-modal-actions {
  display: flex;
  gap: 8px;
  margin-top: 4px;
}

.My-pets-add-vet-modal-cancel {
  background-color: #00000033;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-family: 'Inter-Semibold';
  height: 40px;
  margin-right: 16px;
  width: 114px;
}

.My-pets-add-vet-modal-cancel:hover {
  background-color: #00000050;
}

.My-pets-add-vet-modal-save:hover {
  background-color: #22435ef0;
}

.My-pets-add-vet-modal-input:focus {
  border-radius: 5px;
  outline: 1px solid #000 !important;
}

.My-pets-add-vet-zip-code-input {
  color: #000;
  font-family: 'Gotham-Regular';
  font-size: 14px;
  line-height: 16.8px;
  padding-left: 8px !important;
  padding-top: 6px;
}

.My-pets-add-vet-zip-code-input::placeholder {
  color: var(--pc-color-onyx-300);
  font-family: 'Gotham-Regular';
  font-size: 14px;
  line-height: 16.8px;
}

.My-pets-add-vet-zip-code-input:focus {
  border-radius: 8px;
  border: 2px solid var(--pc-color-blue-300);
  outline: 2px solid var(--pc-color-blue-300);
}

.My-pets-add-vet-zip-code-input-container > input {
  border-radius: 8px;
  border: 2px solid var(--pc-color-onyx-100);
  color: #000;
  display: flex;
  flex-direction: column;
  font-family: 'Gotham-Regular';
  font-size: 14px;
  line-height: 16.8px;
  outline: none;
  width: -webkit-fill-available;
}

div .My-pets-add-vet-zip-code-input:focus {
  border: none !important;
  box-shadow: none !important;
}

.My-pets-add-vet-zip-code-input-error,
.My-pets-add-vet-zip-code-input {
  border-radius: 5px;
  border: 2px solid var(--pc-color-blue-100);
  color: #22435e;
  font-family: 'Inter-Medium';
  font-size: 14px;
  height: 41px;
  outline: 1px solid var(--pc-color-blue-300);
  width: 80px;
}

.My-pets-add-vet-zip-code-input-error {
  background-color: rgba(204, 0, 0, 0.05);
  border: 1px solid #cc0000;
  margin-bottom: 0;
  outline: 1px solid #cc0000;
  padding-left: 8px !important;
}

.My-pets-add-vet-zip-code-input-error:focus {
  background-color: rgba(204, 0, 0, 0.05) !important;
  border: 1px solid #cc0000 !important;
  outline: 1px solid #cc0000 !important;
}

.My-pets-add-vet-zip-code-error {
  color: #cc0000;
  font-family: 'Inter-Regular';
  font-size: 14px;
  margin-bottom: 11px;
}

@media (max-width: 568px) {
  .My-pets-add-vet-modal {
    margin: 24px 40px;
    padding: 0 30px 0 37px;
    width: 440px;
  }
}
